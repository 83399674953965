import React, { useEffect, useRef } from "react"
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"

import { horizontalScrollable, imageContainer } from "./filmGroup.module.css"

const FILM_COVER_WIDTH = 160;
const PADDING_RIGHT_2 = 8; // 8px as pr-2 is 0.5rem

const FilmGroup = ({ title, id, films }) => {
  const sectionRef = useRef(null);

  const onSectionScroll = () => {
    const scrollPosition = sectionRef.current.scrollLeft;
    const visibleAreaWidth = sectionRef.current.getBoundingClientRect().width;

    for (let filmCoverIndex = 0; filmCoverIndex < sectionRef.current.children.length; filmCoverIndex++) {
      const filmCover = sectionRef.current.children[filmCoverIndex];

      const filmCoverStart = filmCoverIndex * FILM_COVER_WIDTH;
      const filmCoverEnd = ((filmCoverIndex + 1) * FILM_COVER_WIDTH) + (filmCoverIndex * PADDING_RIGHT_2);

      // Styling the film cover with a partial opacity when it's partially visible
      if (filmCoverStart >= scrollPosition && filmCoverEnd <= (scrollPosition + visibleAreaWidth)) {
        filmCover.style.opacity = 1;
      } else {
        filmCover.style.opacity = 0.5;
      }
    }
  };

  // Update the view at start. Using the second parameter as [] allows to call the function once
  useEffect(onSectionScroll, []);

  return <>
    <h2 id={id} className="uppercase py-4">{title}</h2>
    <section
      className={`${horizontalScrollable} inline-flex w-full overflow-x-scroll`}
      onScroll={onSectionScroll}
      ref={sectionRef}
    >
      {films.map(({ title: filmTitle, image, slug }, index) => {
        return <article key={index} className="pr-2 h-64">
          <Link to={`/piccoli-sollievi/cineteca/${slug}`}>
            <GatsbyImage
              image={image.childImageSharp.gatsbyImageData}
              alt={filmTitle}
              className={`${imageContainer} rounded-lg`}
            />
          </Link>
        </article>
      })}
    </section>
  </>;
};

export default FilmGroup;
